/* QuizRecommendations.css */

.recommendations-card {
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }
  
  .performance-header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .performance-title {
    margin-left: 24px;
  }
  
  .performance-title h3 {
    margin-bottom: 8px;
  }
  
  .recommendation-message {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 24px;
    padding: 16px;
    background-color: #f0f7ff;
    border-radius: 6px;
    border-left: 4px solid #1890ff;
  }
  
  .performance-insights {
    background-color: #fafafa;
    padding: 16px;
    border-radius: 6px;
    margin-bottom: 24px;
  }
  
  .insight-section {
    margin-bottom: 16px;
  }
  
  .insight-section:last-child {
    margin-bottom: 0;
  }
  
  .insight-section ul {
    margin-top: 8px;
    padding-left: 20px;
  }
  
  .insight-section li {
    margin-bottom: 4px;
  }
  
  .recommendation-options {
    margin-top: 16px;
  }
  
  .recommendation-btn {
    text-align: left;
    height: auto;
    padding: 12px 16px;
  }
  
  .challenge-btn {
    background-color: #52c41a;
    border-color: #52c41a;
  }
  
  .challenge-btn:hover {
    background-color: #389e0d;
    border-color: #389e0d;
  }
  
  .continue-btn {
    background-color: #faad14;
    border-color: #faad14;
    color: #fff;
  }
  
  .continue-btn:hover {
    background-color: #d48806;
    border-color: #d48806;
    color: #fff;
  }
  
  .foundations-btn {
    background-color: #2f54eb;
    border-color: #2f54eb;
    color: #fff;
  }
  
  .foundations-btn:hover {
    background-color: #1d39c4;
    border-color: #1d39c4;
    color: #fff;
  }
  
  .recommended-btn {
    color: #1890ff;
    font-weight: 500;
    text-decoration: underline;
  }
  
  .recommended-btn:hover {
    color: #096dd9;
  }
  
  /* Quiz Results Container */
  .quiz-results-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .results-summary-card {
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }