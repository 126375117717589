/* ImprovedQuizResults.css */

.quiz-results-container {
    max-width: 1200px;
    margin: 0 auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .score-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    background-color: #fafafa;
    border-radius: 8px;
    height: 100%;
  }
  
  .mt-4 {
    margin-top: 16px;
  }
  
  .ml-3 {
    margin-left: 12px;
  }
  
  .w-full {
    width: 100%;
  }
  
  .learning-path-section {
    padding: 16px;
  }
  
  .flex {
    display: flex;
  }
  
  .items-center {
    align-items: center;
  }
  
  .text-center {
    text-align: center;
  }
  
  .text-base {
    font-size: 16px;
  }
  
  .recommendation-message {
    font-size: 16px;
    line-height: 1.6;
    margin: 16px 0;
    padding: 16px;
    background-color: #f0f7ff;
    border-radius: 6px;
    border-left: 4px solid #1890ff;
  }
  
  .insight-section {
    background-color: #fafafa;
    padding: 16px;
    border-radius: 6px;
    height: 100%;
  }
  
  .insight-section ul {
    margin-top: 8px;
    padding-left: 20px;
  }
  
  .insight-section li {
    margin-bottom: 4px;
  }

  .focus-area-item {
    color: #fa541c;
    font-weight: 500;
  }
  
  .mt-2 {
    margin-top: 8px;
  }

  /* Detailed analysis section */
  .detailed-analysis {
    margin: 16px 0;
  }

  .performance-item {
    margin-bottom: 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;
  }

  .performance-item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  .improvement-title {
    display: inline-flex;
    align-items: center;
  }

  .recommendation-note {
    display: block;
    font-size: 12px;
    margin-top: 4px;
    text-align: center;
  }

  .personalization-note {
    background-color: #fff7e6;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid #ffd591;
    margin-top: 16px;
  }
  
  /* Button styles */
  .recommendation-btn {
    height: auto;
    padding: 12px 16px;
    text-align: center;
  }
  
  .challenge-btn {
    background-color: #52c41a;
    border-color: #52c41a;
  }
  
  .challenge-btn:hover:not(:disabled) {
    background-color: #389e0d;
    border-color: #389e0d;
  }

  .challenge-btn:disabled {
    background-color: #d9d9d9;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  
  .balanced-btn {
    background-color: #1890ff;
    border-color: #1890ff;
    color: #fff;
  }
  
  .balanced-btn:hover {
    background-color: #096dd9;
    border-color: #096dd9;
    color: #fff;
  }
  
  .foundations-btn {
    background-color: #fa8c16;
    border-color: #fa8c16;
    color: #fff;
  }
  
  .foundations-btn:hover {
    background-color: #d46b08;
    border-color: #d46b08;
    color: #fff;
  }
  
  /* Responsive adjustments */
  @media (max-width: 767px) {
    .score-section {
      margin-bottom: 16px;
    }

    .detailed-analysis .ant-card {
      margin-bottom: 16px;
    }
  }