/* Responsive styles for the Google Classroom Integration page */

/* General responsive styles for mobile devices */
@media (max-width: 768px) {
  /* Main container padding */
  .classroom-integration-container .ant-layout-content {
    padding: 10px !important;
  }

  /* Page title */
  .classroom-integration-container h2.ant-typography {
    font-size: 20px !important;
    line-height: 1.4 !important;
    word-break: break-word !important;
    overflow-wrap: break-word !important;
  }

  /* Connection status card */
  .classroom-status-connected {
    flex-direction: column !important;
  }

  /* Fix alignment in connected status section */
  .classroom-status-connected > div {
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-bottom: 10px !important;
  }

  /* Button container in the connected status */
  .classroom-status-connected > div:last-child > div {
    margin-top: 10px !important;
    align-self: flex-start !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  }

  /* Adjust buttons to use full width */
  .classroom-status-connected button {
    flex: 1 !important;
    margin-right: 8px !important;
  }

  /* Card title and extra content */
  .connected-classrooms-card .ant-card-head {
    flex-direction: column !important;
    padding: 10px !important;
  }

  .connected-classrooms-card .ant-card-head-title {
    padding: 10px 0 !important;
    text-align: center !important;
  }

  .connected-classrooms-card .ant-card-extra {
    margin-left: 0 !important;
    padding: 10px 0 !important;
    width: 100% !important;
  }

  /* Search and Add button container */
  .connected-classrooms-card .ant-card-extra > div {
    width: 100% !important;
    flex-direction: column !important;
  }

  /* Search input */
  .connected-classrooms-card .ant-input-affix-wrapper {
    width: 100% !important;
    margin-bottom: 10px !important;
  }

  /* Add button */
  .connected-classrooms-card .ant-card-extra button {
    width: 100% !important;
  }

  /* List items in classroom cards */
  .connected-classrooms-card .ant-list-item {
    padding: 15px 10px !important;
  }

  /* Course item title and metadata */
  .connected-classrooms-card .ant-list-item > div > div:first-child {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  /* Course metadata */
  .connected-classrooms-card
    .ant-list-item
    > div
    > div:first-child
    > div:nth-child(2) {
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 5px !important;
    margin-top: 10px !important;
    width: 100% !important;
  }

  /* Assignment list in each course */
  .connected-classrooms-card .ant-space {
    margin-left: 0 !important;
    margin-top: 5px !important;
    display: block !important;
  }

  /* Assignment item */
  .connected-classrooms-card .ant-space-item {
    display: block !important;
    margin-bottom: 5px !important;
  }

  /* Button container in each course */
  .connected-classrooms-card
    .ant-list-item
    > div
    > div:nth-child(2)
    > div:last-child {
    justify-content: space-between !important;
    margin-top: 15px !important;
    width: 100% !important;
  }

  /* Make buttons in course items use equal width */
  .connected-classrooms-card .ant-list-item button {
    flex: 1 !important;
    text-align: center !important;
    padding: 4px 8px !important;
  }

  /* Recent Assignments table - Enhanced scrolling without hiding columns */
  .classroom-integration-container .ant-table {
    overflow-x: auto !important;
    display: block !important;
    width: 100% !important;
    white-space: nowrap;
  }

  /* Improved table scroll handling */
  .ant-table-wrapper {
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch !important; /* Better scroll on iOS */
  }

  .ant-table {
    min-width: 500px !important;
    table-layout: fixed !important;
  }

  .ant-table-container {
    width: auto !important;
    overflow-x: auto !important;
  }

  /* Create New Assignment button */
  .classroom-integration-container > div > button {
    width: 100% !important;
    margin-top: 20px !important;
  }

  /* Modal width adjustment */
  .ant-modal {
    max-width: 95vw !important;
    margin: 0 auto !important;
  }

  .ant-modal-content {
    padding: 15px !important;
  }

  /* Fix for Create New Assignment button */
  .classroom-integration-container .ant-layout-content > div:last-child {
    width: 100% !important;
  }

  .classroom-integration-container
    .ant-layout-content
    > div:last-child
    > button {
    width: 100% !important;
  }
}

/* Extra small devices (phones, 480px and down) */
@media (max-width: 480px) {
  /* Further reduce padding */
  .classroom-integration-container .ant-layout-content {
    padding: 8px !important;
  }

  /* Make title even smaller */
  .classroom-integration-container h2.ant-typography {
    font-size: 18px !important;
  }

  /* Adjust course title */
  .connected-classrooms-card h4.ant-typography {
    font-size: 16px !important;
  }

  /* Make table more compact */
  .classroom-integration-container .ant-table th,
  .classroom-integration-container .ant-table td {
    padding: 8px 4px !important;
    font-size: 12px !important;
    white-space: nowrap !important;
  }

  /* Ensure table content is scrollable */
  .ant-table-content {
    overflow-x: auto !important;
    scrollbar-width: thin !important;
  }

  /* Add visual indicator for horizontal scroll */
  .classroom-integration-container .ant-table::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 10px;
    background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.05));
    pointer-events: none;
  }
}

/* Table scroll container styles */
.table-scroll-container {
  position: relative;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.table-scroll-container::-webkit-scrollbar {
  height: 6px;
}

.table-scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.table-scroll-container::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Recent assignments card styling */
.recent-assignments-card .ant-card-body {
  padding: 0 !important;
}

.recent-assignments-card .ant-table-wrapper {
  margin: 0 !important;
}
