@charset "utf-8";
/* CSS Document */


/*

// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... }

*/
/* X-Large devices (large desktops, less than 1600px) */
@media(max-width:1600.98px) {}

/* X-Large devices (large desktops, less than 1500px) */
@media (max-width: 1440.98px) {

    /* for mobile menu responsive section responsive css start */
    .header .menu-link {
        font-size: 18px;
    }

    .header-phone-number-section a {
        font-size: 18px;
    }

    .header-get-call-section a {
        font-size: 15px;
        padding: 11px 25px;
    }

    .header-phone-number-section {
        margin: 0px 20px;
    }

    /* for mobile menu responsive section responsive css end */
    .brand img {
        width: 135px;
    }
    .nav {
        padding: 4px 0px;
    }
    .main-banner-text h1 {
        font-size: 40px;
    }
    .main-banner-text p {
        font-size: 18px;
    }
    .banner-right-side-image img {
        height: 635px;
    }
    .solid-btn {
        font-size: 18px;
    }
    .about-us-section {
        margin: 270px 0px 60px 0px;
    }
    .about-us-text h2 {
        font-size: 45px;
        margin-bottom: 6px;
    }
    .about-us-section-background-right-side-circle-image img {
        width: 513px;
        height: 430px;
    }
    .about-us-section-background-right-side-circle-image {
        top: 4%;
    }
    .key-features-section {
        margin: 60px 0px;
        padding: 467px 0px 109px 0px;
    }
    .key-features-slider-section {
        margin-left: 6%;
        margin-right: -6%;
        margin-top: 43px;
    }
    .key-features-slider-section .slick-prev {
        top: -35% !important;
    }
    .key-features-slider-section .slick-next {
        top: -35% !important;
    }
    .key-features-slider-card a {
        height: auto;
    }
    .key-features-slider-card-description p {
        height: 196px;
        overflow-x: hidden;
        padding-right: 10px;
        overflow-y: auto;
    }
    .key-features-section-title h2 {
        font-size: 45px;
    }
    .key-features-section-title p {
        font-size: 16px;
        line-height: 24px;
    }
    .key-features-slider-card-title h5 {
        font-size: 19px;
    }
    .key-features-slider-card-description p {
        font-size: 16px;
        line-height: 22px;
    }
    .contact-us-section::after {
        width: 1010px;
        height: 1797px;
    }
    .contact-us-right-section-title h2 {
        font-size: 45px;
    }
    .contact-us-right-section-title p {
        font-size: 16px;
    }
    .contact-us-section-image img {
        height: 580px;
    }
    .footer {
        margin: 60px 0px 0px 0px;
    }
    .footer-logo img {
        width: 194px;
    }
    .login-form-section {
        margin: 60px 0px;
    }
    .login-from-background-image img {
        width: 778px;
        height: 622px;
    }
    .login-from-background-image {
        top: 2px;
    }
    .login-from-card-title {
        font-size: 40px !important;
        margin-bottom: 11px !important;
    }
    .login-form-card h3 {
        font-size: 19px !important;
        margin-bottom: 19px !important;
    }
    .login-form-right-side-image img {
        height: 570px;
    }
    .card-paragraph-text {
        font-size: 16px !important;
        line-height: 24px !important;
    }
    .views-welcome-title-text {
        font-size: 45px !important;
    }
    .views-card-section {
        margin: 60px 0px;
    }
    .title-bottom-text {
        font-size: 20px !important;
    }
    .export-text-export-drive-btn button {
        font-size: 15px !important;
        padding: 11px 24px;
    }
    .ask-praro-feedback-btn .feedback-button {
        font-size: 15px !important;
        padding: 11px 90px;
    }
    .ask-praro-feedback-btn .ask-praro-btn {
        font-size: 15px !important;
        padding: 11px 90px;
    }
    .query-text {
        font-size: 22px !important;
    }
    .key-features-section-list-text li {
        font-size: 16px;
        color: #044800;
    }
}

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
    .header .menu-item {
        margin: 0px 6px;
    }
    
    .header .menu-link {
        font-size: 18px;
    }
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

    /* for mobile menu responsive section responsive css start */
    .header .menu-link {
        padding: 0px 4px;
        font-size: 16px;
    }

    .header .menu-item {
        margin: 0px 5px;
    }
    
    .menu-section {
        display: block;
    }
}

@media (max-width: 991.98px) {
    .header .menu-block {
        display: block;
    }
    
    .header .menu-item {
        display: block;
        margin: 0px 0px;
        border-bottom: 1px solid #eee;
    }

    .header .menu-link {
        display: block;
        padding: 9px 15px;
        font-size: 18px;
    }
    
    .menu-section {
        display: block;
    }
    
    .header-get-call-section {
        text-align: center;
        margin-top: 0px;
    }

    .header-phone-number-section a {
        display: none;
    }

    .responsive-phone-icon {
        display: block;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .main-banner-text h1 {
        font-size: 30px;
    }
    .solid-btn {
        font-size: 15px;
        padding: 13px 34px;
    }
    .home-page-main-banner::after {
        bottom: -13%;
        height: 156px;
    }
    .banner-right-side-image img {
        height: 490px;
    }
    .about-us-section {
        margin: 120px 0px 30px 0px;
    }
    .about-us-text h2 {
        font-size: 30px;
        margin-bottom: 6px;
    }
    .about-us-section-background-right-side-circle-image img {
        width: 330px;
        height: 210px;
    }
    .about-us-text p {
        font-size: 15px;
        line-height: 25px;
    }
    .about-us-section-background-right-side-circle-image {
        top: 3%;
    }
    .section-btn {
        font-size: 17px;
        padding: 13px 34px;
    }
    .key-features-section::before {
        height: 197px;
    }
    .key-features-section {
        margin: 3px 0px;
        padding: 243px 0px 109px 0px;
    }
    .key-features-section-title h2 {
        font-size: 30px;
    }
    .key-features-section-title p {
        font-size: 15px;
        line-height: 20px;
    }
    .key-features-slider-section {
        margin-left: 14%;
        margin-right: -20%;
        margin-top: 18px;
    }
    .key-features-slider-section .item {
        padding: 0px 6px;
    }
    .key-features-slider-section .slick-prev {
        width: 49px;
        height: 49px;
        font-size: 18px;
    }
    .key-features-slider-section .slick-next {
        width: 49px;
        height: 49px;
        font-size: 18px;
    }
    .contact-us-section::after {
        display: none;
    }
    .contact-us-section {
        margin: 60px 0px 60px 0px;
        padding: 60px 0px 0px 0px;
    }
    .contact-us-section-image img {
        height: 505px;
        margin-bottom: 22px;
    }
    .contact-us-right-section-title h2 {
        font-size: 30px;
    }
    .footer {
        margin: 30px 0px 0px 0px;
        padding: 156px 0px 0px 0px;
    }
    .footer-logo img {
        width: 150px;
    }
    .footer-quick-link ul li a {
        font-size: 18px;
        margin: 0px 24px;
    }
    .footer-support-mail-text h5 {
        font-size: 18px;
    }
   .ant-card .ant-card-body {
        padding: 4px;
    }
    .login-form-right-side-image img {
        height: 446px;
    }
    .login-from-background-image img {
        width: 778px;
        height: 496px;
    }
    .login-from-card-title {
        font-size: 25px !important;
        line-height: 30px !important;
    }
    .login-form-input-section .login-form-input {
        font-size: 15px !important;
    }
    .input-field-icon i {
        color: #7B6F72;
        font-size: 19px;
    }
    .create-acount-google-sign-in-btn {
        display: block;
    }
    .google-sign-in-btn {
        text-align: left;
        margin-top: 10px !important;
    }
    .create-acount-google-sign-in-btn::after {
        display: none;
    }
    .view-cards .key-features-slider-card-bottom-icon {
       display: none;
    }
    .view-card-call-section {
        margin-bottom: 22px;
    }
    .login-form-select-input .ant-select-selection-item{
        font-size: 15px !important;
      
    }
    .detail-form-section .login-form-input {
        padding: 12px 15px 12px 15px !important;
    }
   .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
        padding: 12px 0px 12px 15px;
    }
    .detail-form-section .ant-select-arrow {
        width: 46px;
        height: 46px;
        top: 20%;
        right: 4px;
        font-size: 15px;
    }
    .query-text-export-btn-section{
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-bottom: 9px;
    }
    .export-text-export-drive-btn {
        margin-bottom: 25px;
    }
    .ask-praro-feedback-btn .feedback-button {
        padding: 11px 46px;
        margin-right: 14px;
    }
    .ask-praro-feedback-btn .ask-praro-btn {
        padding: 11px 46px;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .key-features-section-list-text li {
        margin: 5px 17px;
    }
    .home-page-banner-inner-section {
        padding: 60px 0px;
    }
    .main-banner-text h1 {
        font-size: 25px;
    }
    .main-banner-text p {
        font-size: 15px;
        line-height: 24px;
    }
    .main-banner-text{
        text-align: center;
    }
    .banner-right-side-image{
        margin-top: 22px;
    }
    .about-us-text h2 {
        font-size: 25px;
        margin-bottom: 6px;
    }
    .key-features-section::before {
        height: 153px;
    }
    .key-features-section {
        margin: 3px 0px;
        padding: 189px 0px 109px 0px;
    }
    .key-features-slider-card-description p {
        height: 134px;
    }
    .key-features-section-title h2 {
        font-size: 25px;
        margin-bottom: 4px;
    }
    .key-features-slider-section {
        margin-top: 48px;
        margin-left: 16%;
    }
    .key-features-slider-section .slick-prev {
        top: -13% !important;
        width: 36px;
        height: 36px;
        font-size: 12px;
    }
    .key-features-slider-section .slick-next {
        top: -13% !important;
        width: 36px;
        height: 36px;
        font-size: 12px;
    }
    .contact-us-section-image img {
        height: 447px;
        margin-bottom: 22px;
    }
    .key-features-slider-card-bottom-icon {
        width: 46px;
        height: 46px;
        font-size: 19px;
    }
    .contact-us-right-section-title h2 {
        font-size: 25px;
        margin-bottom: 5px;
    }
    .contact-from-label-input-section label {
        font-size: 16px;
        margin-bottom: 9px;
    }
    .contact-us-submit-btn input {
        font-size: 17px;
        padding: 13px 56px 13px 46px;
    }
    .contact-us-submit-btn i {
        left: 127px;
        top: 16px;
    }
    .footer::before {
        height: 87px;
    }
    .footer {
        margin: 30px 0px 0px 0px;
        padding: 113px 0px 0px 0px;
    }
    .footer-logo img {
        width: 123px;
    }
    .footer-quick-link ul li a {
        font-size: 16px;
        margin: 0px 16px;
    }
    .footer-support-mail-text h5 {
        font-size: 16px;
    }
    .footer-support-mail-icon {
        width: 44px;
        height: 44px;
        font-size: 20px;
    }
    .footer-copy-right-text p {
        font-size: 16px;
    }
    .footer-quick-link {
        padding: 19px 0px;
        margin: 25px 0px;
    }
    .login-from-row-section{
        flex-direction: column-reverse;
    }
    .login-form-right-side-image{
        margin-bottom: 22px;
    }
    .login-from-background-image {
        top: 19%;
        right: -45%;
    }
    .login-from-background-image img {
        width: 553px;
        height: 363px;
    }
    .login-form-card h3 {
        font-size: 15px !important;
        margin-bottom: 19px !important;
    }
    .views-welcome-title-text {
        font-size: 30px !important;
    }
    
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .banner-right-side-image img {
        height: 346px;
    }
    .home-page-banner-inner-section {
        padding: 30px 0px;
    }
    .home-page-main-banner::after {
        bottom: -7%;
        height: 66px;
    }
    .main-banner-text h1 {
        font-size: 22px;
    }
    .brand img {
        width: 84px;
    }
    .about-us-section-background-right-side-circle-image {
        top: 18%;
        right: -49%;
    }
    .about-us-section {
        margin: 60px 0px 30px 0px;
    }
    .about-us-text h2 {
        font-size: 22px;
        margin-bottom: 6px;
    }
    .about-us-text p {
        font-size: 14px;
        line-height: 22px;
    }
    .section-btn {
        font-size: 15px;
    }
    .key-features-slider-section {
        margin-top: 48px;
        margin-left: 1%;
    }
    .key-features-slider-icon {
        width: 54px;
        height: 54px;
        padding: 15px;
    }
    .key-features-slider-icon img {
        width: 34px;
        height: 34px;
        object-fit: contain;
    }
    .key-features-slider-card-title h5 {
        font-size: 16px;
    }
    .key-features-slider-card-description p {
        font-size: 14px;
        line-height: 17px;
    }
    .key-features-slider-section .slick-prev {
        right: 37% !important;
    }
    .key-features-section::before {
        height: 86px;
    }
    .key-features-section::after {
        bottom: -6%;
        height: 118px;
    }
    .key-features-section-title h2 {
        font-size: 22px;
        margin-bottom: 4px;
    }
    .key-features-section-title p {
        font-size: 14px;
        line-height: 18px;
    }
    .key-features-section {
        margin: 3px 0px;
        padding: 110px 0px 83px 0px;
    }
    .contact-us-section {
        margin: 30px 0px 30px 0px;
        padding: 30px 0px 0px 0px;
    }
    .contact-us-section-image img {
        height: 309px;
    }
    .contact-us-right-section-title h2 {
        font-size: 22px;
    }
    .contact-us-right-section-title p {
        font-size: 14px;
        line-height: 21px;
    }
    .contact-from-label-input-section {
        margin-bottom: 13px;
    }
    .footer::before {
        height: 53px;
    }
    .footer-logo img {
        width: 108px;
    }
    .footer {
        margin: 30px 0px 0px 0px;
        padding: 77px 0px 0px 0px;
    }
    .footer-copy-right-text p {
        font-size: 15px;
    }
    .footer-copy-right-section {
        margin-top: 21px;
        padding: 16px 0px;
    }
    .login-from-background-image {
        display: none;
    }
    .login-form-section {
        margin: 30px 0px;
    }
    .login-form-right-side-image img {
        height: 360px;
    }
    .login-from-card-title {
        font-size: 22px !important;
    }
    .title-bottom-text {
        font-size: 14px !important;
        line-height: inherit !important;
        margin-bottom: 18px !important;
    }
    .view-card-call-section {
        margin-bottom: 11px;
    }
    .views-card-section {
        margin: 30px 0px;
    }
    .views-welcome-title-text {
        font-size: 25px !important;
    }
    .details-goback-btn {
        font-size: 16px;
    }
    .export-text-export-drive-btn button {
        font-size: 13px !important;
        padding: 8px 12px;
    }
    .ask-praro-feedback-btn .feedback-button {
        padding: 9px 20px;
        margin-right: 13px;
    }
    .ask-praro-feedback-btn .ask-praro-btn {
        padding: 9px 20px;
        margin-right: 13px;
    }
    .ask-praro-feedback-btn {
        margin-top: 11px;
    }
    .query-text {
        font-size: 19px !important;
        margin-bottom: 14px;
    }
    .key-features-slider-section .view-cards {
        height: 260px !important;
    }
    .key-features-section-list-text li {
        font-size: 14px;
        margin: 3px 14px;
    }
}