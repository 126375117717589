/* rubik font start */
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

@font-face {
	src: url(../fonts/rubik-font/Rubik-Black.ttf), format('ttf');
}
@font-face {
	src: url(../fonts/rubik-font/Rubik-Bold.ttf), format('ttf');
}
@font-face {
	src: url(../fonts/rubik-font/Rubik-ExtraBold.ttf), format('ttf');
}
@font-face {
	src: url(../fonts/rubik-font/Rubik-Italic.ttf), format('ttf');
}
@font-face {
	src: url(../fonts/rubik-font/Rubik-Light.ttf), format('ttf');
}
@font-face {
	src: url(../fonts/rubik-font/Rubik-Medium.ttf), format('ttf');
}
@font-face {
	src: url(../fonts/rubik-font/Rubik-Regular.ttf), format('ttf');
}
@font-face {
	src: url(../fonts/rubik-font/Rubik-SemiBold.ttf), format('ttf');
}
/* rubik font end*/
.sticky {
   top: 0 !important;
   background-color: #fff !important;
   -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
   animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
   position: fixed !important;
   box-shadow: 0px 5px 35px 0px rgb(211 221 241 / 75%);
   z-index: 999;
   width: 100%;
}

.menu-section {
   display: flex;
   align-items: center;
}

/* nav css start */
.nav {
   background: #EDFFEB;
   position: relative;
   z-index: 9999;
   width: 100%;
   padding: 15px 0px;
}

.header {

   left: 0;
   top: 0;
   width: 100%;
   height: auto;
   z-index: 999;
   margin: 0 auto;


}

.header .navbar {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 2rem;
   max-width: 100%;
   height: auto;
   padding: 0.75rem 0;
}

.nav-btn {
   background: #33B6EF;
   color: #fff !important;
   padding: 12px 25px !important;
   border-radius: 10px;
   transition: 0.6s ease all;
   line-height: 34px !important;
   text-align: center;
   font-size: 18px !important;


}

.nav-btn:hover {
   background: #000;
}

.header .burger {
   position: relative;
   display: block;
   cursor: pointer;
   width: 2rem;
   height: auto;
   border: none;
   opacity: 1;
   visibility: visible;
   background: none;
   outline: none;
}

.brand img {
   width: 200px;
}

.header-phone-number-section i {
   font-size: 18px;
}

.responsive-phone-icon {
   display: none;
}

@media only screen and (min-width: 1200px) {
   .header .burger {
      display: none;
      opacity: 0;
      visibility: hidden;
   }
}

.header .burger-line {
   display: block;
   cursor: pointer;
   width: 100%;
   height: 2px;
   margin: 6px auto;
   background: #0875f7;
   transform: rotate(0deg);
   transition: all 0.3s ease-in-out;
}

.header .menu {
   position: fixed;
   top: 0;
   left: -19rem;
   width: 19rem;
   height: 100%;
   z-index: 999;
   opacity: 0;
   overflow-y: auto;
   visibility: hidden;
   background: #fff;
   box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
   transition: all 0.5s ease;
}

@media only screen and (min-width: 1200px) {
   .header .menu {
      position: relative;
      top: 0;
      left: 0;
      width: auto;
      opacity: 1;
      visibility: visible;
      background: none;
      box-shadow: none;
      width: 76%;
   }
}

.header .menu.is-active {
   left: 0rem;
   opacity: 1;
   visibility: visible;
}

.header .menu-block {
   /* width: 100%; */
   height: auto;
   margin: 2rem 0;
   display: flex;
   flex-wrap: wrap;
}

@media only screen and (min-width: 991px) {
   .header .menu-block {
      margin: 16px auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: nowrap;
   }
}

.header .menu-item {
   display: block;
   margin: 0;
}

@media only screen and (min-width: 991px) {
   .header .menu-item {
      position: relative;
      display: inline-block;
      margin: 0px 10px;
      white-space: nowrap;
   }
}

.header .menu-link {
   display: block;
   font-family: inherit;
   line-height: inherit;
   padding: 0px 10px;
   border: none;
   text-rendering: optimizeLegibility;
   transition: all 0.35s ease;
   font-weight: 500;
   font-size: 20px;
   line-height: 30px;
   color: #000;
   font-family: "Rubik", sans-serif;
}

.active-header-link {
   color: #0875F7 !important;
}

.header .menu-link:hover {
   color: #0875F7 ;
}

.header-phone-number-section a {
   font-weight: 400;
   font-size: 16px;
   text-transform: capitalize;
   color: #000000;
}

.header-phone-number-section a:hover {
   color: #0875f7;
}

.header-phone-number-section {
   margin: 0px 33px;
}

.header-get-call-section a {
   background: transparent;
   border: 2px solid #0875F7;
   border-radius: 50px;
   color: #0875F7;
   padding: 15px 41px;
   transition: 0.6s ease all;
   font-size: 20px;
   display: inline-block;
   font-family: "Rubik", sans-serif;
   white-space: nowrap;
}

.header-get-call-section a:hover {
   background: #0875F7;
   color: #fff;
   border: 2px solid transparent;
}

@media only screen and (min-width: 991px) {
   .header .menu-link {
      padding: -0.5rem 19px;
   }

}

@media only screen and (max-width: 991px) {
   .header .menu-link {
      color: var(--color-black);

   }

}

.header .overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 999;
   opacity: 0;
   visibility: hidden;
   background: rgba(0, 0, 0, 0.7);
   transition: all 0.35s ease-in-out;
}

.header .overlay.is-active {
   display: block;
   opacity: 1;
   visibility: visible;
}

/* Add specific styling for dropdown-for-desktop */
.dropdown-for-desktop {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 991px) {
  .dropdown-for-desktop {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
}

/* nav css link end */