/* Responsive styles for mobile devices */

/* General responsive styles */
@media (max-width: 768px) {
  /* Improve content display on small screens */
  .markdown-content {
    font-size: 16px !important;
    line-height: 1.6 !important;
    overflow-x: hidden !important;
    word-break: break-word !important;
  }

  /* Handle tables in responsive mode */
  .markdown-content table {
    width: 100% !important;
    display: block !important;
    overflow-x: auto !important;
  }

  /* Make code blocks more mobile-friendly */
  .markdown-content pre,
  .markdown-content code {
    white-space: pre-wrap !important;
    word-break: break-word !important;
    max-width: 100% !important;
  }

  /* Responsive images */
  .markdown-content img {
    max-width: 100% !important;
    height: auto !important;
  }

  /* Response card button container */
  .export-text-export-drive-btn {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
  }

  /* Response card buttons */
  .export-text-export-drive-btn button {
    margin-bottom: 10px !important;
    width: 100% !important;
  }

  /* Feedback buttons */
  .ask-praro-feedback-btn {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
  }

  .ask-praro-feedback-btn button {
    margin-bottom: 10px !important;
    width: 100% !important;
  }

  /* Lists in response content */
  .markdown-content ul,
  .markdown-content ol {
    padding-left: 20px !important;
  }
}

/* Specific styles for smaller mobile screens */
@media (max-width: 480px) {
  .markdown-content {
    font-size: 14px !important;
  }

  /* Make headings more compact */
  .markdown-content h1 {
    font-size: 1.6em !important;
  }

  .markdown-content h2 {
    font-size: 1.4em !important;
  }

  .markdown-content h3 {
    font-size: 1.2em !important;
  }
}
