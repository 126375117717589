/* Quiz.css - Compact design */

/* Main container */
.quiz-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

/* Progress section */
.progress-container {
  margin-bottom: 16px;
}

.progress-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.question-counter {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.progress-percentage {
  font-size: 14px;
  color: #666;
}

/* Question styling */
.question-container {
  background-color: #f0f7ff;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 16px;
}

.question-text {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  line-height: 1.4;
}

/* Custom radio buttons */
.custom-options-container {
  margin-bottom: 16px;
}

.custom-option {
  display: flex;
  align-items: flex-start;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.custom-option:hover {
  border-color: #1890ff;
  background-color: #f0f8ff;
}

.custom-option.selected {
  border-color: #1890ff;
  background-color: #e6f7ff;
}

.custom-option.correct {
  border-color: #52c41a;
  background-color: #f6ffed;
}

.custom-option.incorrect {
  border-color: #ff4d4f;
  background-color: #fff1f0;
}

.custom-radio {
  margin-right: 8px;
  margin-top: 2px;
}

.option-content {
  display: flex;
  align-items: flex-start;
  flex: 1;
}

.option-key {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: #f0f0f0;
  color: #444;
  font-weight: 500;
  text-align: center;
  line-height: 20px;
  flex-shrink: 0;
}

.selected .option-key,
.custom-option:active .option-key {
  background-color: #1890ff;
  color: #fff;
}

.correct .option-key {
  background-color: #52c41a;
  color: #fff;
}

.incorrect .option-key {
  background-color: #ff4d4f;
  color: #fff;
}

.option-text {
  font-size: 15px;
  line-height: 1.4;
  flex: 1;
}

/* Feedback styling */
.feedback-banner {
  padding: 12px;
  border-radius: 6px;
  margin: 12px 0;
}

.correct-feedback {
  background-color: #f6ffed;
  border-left: 4px solid #52c41a;
}

.incorrect-feedback {
  background-color: #fff1f0;
  border-left: 4px solid #ff4d4f;
}

.feedback-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.feedback-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.correct-feedback .feedback-icon {
  color: #52c41a;
}

.incorrect-feedback .feedback-icon {
  color: #ff4d4f;
}

.feedback-title {
  font-size: 15px;
  font-weight: 600;
}

.correct-feedback .feedback-title {
  color: #52c41a;
}

.incorrect-feedback .feedback-title {
  color: #ff4d4f;
}

.feedback-content {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.feedback-explanation {
  margin-top: 8px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
}

/* Navigation buttons */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.right-buttons {
  display: flex;
  gap: 8px;
}

.nav-button {
  display: flex;
  align-items: center;
}

.nav-icon {
  margin-right: 6px;
}

.similar-button {
  background-color: #e6f7ff;
  border-color: #1890ff;
  color: #1890ff;
}

.similar-button:hover {
  background-color: #bae7ff;
  border-color: #1890ff;
  color: #1890ff;
}

.next-button, .check-button {
  background-color: #21b573;
  border-color: #21b573;
}

.next-button:hover, .check-button:hover {
  background-color: #1a9d64;
  border-color: #1a9d64;
}

/* Responsive fixes */
@media (max-width: 768px) {
  .quiz-content {
    padding: 12px;
  }
  
  .question-container {
    padding: 12px;
  }
  
  .right-buttons {
    flex-direction: column;
    gap: 8px;
  }
}

/* Add these to your existing Quiz.css */

/* Floating Primary Button (Best option) */
.navigation-container {
  position: relative;
  padding-bottom: 24px;
  margin-top: 16px;
}

.navigation-container .secondary-button {
  position: absolute;
  left: 0;
  bottom: 0;
}

.navigation-container .primary-button-container {
  display: flex;
  justify-content: center;
}

.navigation-container .primary-button {
  min-width: 160px;
}

/* When showing feedback, use this layout instead */
.navigation-buttons-feedback {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.navigation-buttons-feedback .left-button {
  flex: 0 0 auto;
}

.navigation-buttons-feedback .right-buttons {
  display: flex;
  gap: 8px;
}

/* Loading States styling */
.loading-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  max-width: 600px;
  margin: 0 auto;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
}

.loading-progress, .loading-spinner {
  margin-bottom: 24px;
}

.loading-spinner .ant-spin-dot {
  font-size: 40px;
}

.loading-spinner .ant-spin-dot-item {
  background-color: #21b573;
}

.loading-message h3 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
}

.loading-message p {
  font-size: 14px;
  color: #666;
  margin: 0;
}

.loading-tips {
  width: 100%;
  background-color: #f0f7ff;
  border-radius: 6px;
  padding: 16px;
  border-left: 4px solid #1890ff;
}

.tip-container {
  display: flex;
  align-items: flex-start;
}

.tip-icon {
  font-size: 18px;
  margin-right: 12px;
  line-height: 1.2;
}

.tip-text {
  font-size: 14px;
  color: #444;
  line-height: 1.5;
}

/* Loading animation */
.loading-dots {
  display: inline-block;
}

.loading-dots:after {
  content: '.';
  animation: dots 1.5s steps(5, end) infinite;
}

@keyframes dots {
  0%, 20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  80% {
    content: '....';
  }
  100% {
    content: '.....';
  }
}

/* Pulse animation for the spinner */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(33, 181, 115, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(33, 181, 115, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(33, 181, 115, 0);
  }
}

.loading-spinner {
  animation: pulse 2s infinite;
  border-radius: 50%;
  padding: 10px;
}